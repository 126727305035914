import { Box, Dialog, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import workerimg from "../../assets/images/workerimage.png";
import "./ProductDetailCard.css";
import MDButton from "components/MDButton";
import { UI_BASE_URL } from "axiosinstance";
import PrintQRCode from "components/PrintQRCode";

const ProductDetailCard = (props) => {
  console.warn("dhdhhdhdh",props)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    // Options for formatting time
    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };

    const formattedDate = dateTime.toLocaleDateString();
    const formattedTime = dateTime
      .toLocaleTimeString(undefined, timeOptions)
      .toUpperCase();

    return `${formattedDate} ${formattedTime}`;
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box style={{ display: "grid", placeItems: "center" }}>
      <Grid container className="card-styling">
        <Grid
          item
          xs={12}
          sm={windowWidth < 1100 ? 12 : 3}
          mr={windowWidth < 1100 ? 2.5 : 0}
        >
          <Box className="img-placeholder">
            <img src={workerimg} alt="worker" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={windowWidth < 1100 ? 12 : 8}>
          <Grid container spacing={3} style={{ fontSize: 17 }}>
            <Grid item xs={12} sm={9}>
              <h3>Name: {props?.data?.name}</h3>
              <Box style={{ fontSize: 17 }}>
                <Box>
                  <b>Type:</b> {props?.data?.type}
                </Box>
                <Box>
                  <b>Station:</b> {props?.data?.stationname} (
                  {props?.data?.stationcode})
                </Box>
                <Box>
                  <b>Model no:</b> {props?.data?.modelno}
                  
                </Box>
                <Box>
                  <b>Transaction Id:</b> {props?.data?.itemcode}
                </Box>
                <Box>
                  <b>Created Date:</b> {formatDateTime(props?.data?.createddate)}
                </Box>
                <Box>
                  <b>Rework:</b> {props?.data?.rework? props?.data?.rework : "false"}
                </Box>
                <Box>
                  <b>Rework Count:</b> {props?.data?.reworkcount || ""}
                </Box>
                <Box>
                  <b>Bypass:</b> {props?.data?.bypass? props?.data?.bypass : "false"}
                </Box>
                <Box>
                  <b>Setup Part:</b> {props?.data?.setuppart === "true"? <MDButton color="warning" variant="contained" size="small">setup part</MDButton>:'NA'}
                </Box>
                {props?.data?.modifieddate === null ||
                props?.data?.modifieddate === undefined ? (
                  <Box>
                    <b>Modified Date:</b> --/--/--
                  </Box>
                ) : (
                  <Box>
                    <b>Modified Date:</b>{" "}
                    {formatDateTime(props?.data?.modifieddate)}
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={3}>
              {props?.data?.recstatus == "OK" ? (
                <MDButton variant="contained" color="success" size="small">
                  Pass
                </MDButton>
              ) : (
                <MDButton variant="contained" color="error" size="small">
                  Fail
                </MDButton>
              )}
              <Box px={1} mt={4}>
                <PrintQRCode data={`{"PARTNO":${props.name}, "VENDORID": "11694-1"}`} title={props.name} stationcode={props?.data?.stationcode} />
              </Box> 
            </Grid>
          </Grid>
          {/* <Box style={{ fontSize: 17 }}> 
            <Box>
              <b>Type:</b> {props?.data?.type}
            </Box>
            <Box>
              <b>Station:</b> {props?.data?.stationname} (
              {props?.data?.stationcode})
            </Box>
            <Box>
              <b>Transaction Id:</b> {props?.data?.itemcode}
            </Box>
            <Box>
              <b>Created Date:</b> {formatDateTime(props?.data?.createddate)}
            </Box>
            <Box>
              <b>Bypass:</b> {props?.data?.bypass}
            </Box>
            <Box>
              <b>Setup Part:</b> {props?.data?.setuppart}
            </Box>
            {props?.data?.modifieddate === null ||
            props?.data?.modifieddate === undefined ? (
              <Box>
                <b>Modified Date:</b> --/--/--
              </Box>
            ) : (
              <Box>
                <b>Modified Date:</b>{" "}
                {formatDateTime(props?.data?.modifieddate)}
              </Box>
            )}
          </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductDetailCard;
