import axios from "axios";
 
export const BASE_URL = 'https://sm.softwaremathematics.com/';
// export const BASE_URL = 'http://ma-srvvdlapp.dayco.local/';
export const UI_BASE_URL = 'http://localhost';

 
 const instance = axios.create({
    baseURL: BASE_URL,
 });
 instance.defaults.headers.common['X-Tenant']='DAT20240418';
 
 export default instance;
  

