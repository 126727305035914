import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Card,
} from "@material-ui/core";
import Stomp from "stompjs";
import SockJS from "sockjs-client";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDInput from "components/MDInput";

import MDButton from "components/MDButton";
import { Autocomplete, Grid, TextField } from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Errorcard from "errorcard/Errorcard";
import { getBomstation } from "app/step/service/BomStation";
import ItemAccordian from "app/step/ItemAccordian";
import ProductDetailCard from "./ProductDetailCard";
import { UI_BASE_URL } from "../../axiosinstance";
import {
  setFilteredMessages,
  setLoading,
  setError,
} from "app/webSocketUI/reducer/filteredMessagesSlice";
import { getPartName } from "./service/PartName";
import Rolecode from "../../Rolecode";
import MDBox from "components/MDBox";
import { getStation } from "app/stationMaster/service/Station";
import CloseIcon from "@mui/icons-material/Close";
import PrintQRPopup from "components/PrintQRPopup";
import { setfilterClear } from "./reducer/filteredMessagesSlice";
import PrintQRCode from "components/PrintQRCode";


const WebSocketUI = () => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [stompClient, setStompClient] = useState(null);
  const dispatch = useDispatch();
  const { bomstation, loading1 } = useSelector((state) => state.bomstation);
  const { operatorCode } = useSelector((state) => state.operatorCode);
  const [expandedStep, setExpandedStep] = useState(-1);
  const [stationcode, setStationcode] = useState("");
  const [data, setData] = useState({});
  const [error1, setError1] = useState("");
  const [hovered, setHovered] = useState(false);
  const [steps, setSteps] = useState([]);
  const [defaultMMU, setDefaultMMU] = useState();
  const { station } = useSelector((state) => state.station);
  const [openpopup, setOpenpopup] = useState(false);
  const [link, setLink] = useState("");
  // const [filteredMessages, setFilteredMessages] = useState([]);
  const { filteredMessages, error } = useSelector(
    (state) => state.filteredMessages
  );
  const [title, setTitle] = useState('')
 
  const { partname, loading } = useSelector((state) => state.partname);
 
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  var rolecode = Rolecode();

  // Extracting values from the path
  const pathSegments = location.pathname.split("/");
  const name = pathSegments[5];
  const code = pathSegments[4];

  const defaultpropsstation = {
    options: !station
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : station,
    getOptionLabel: (option) => option.name + "(" + option.code + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  useEffect(() => {
    const fetchData = async () => {
      // Dispatch the action and wait for it to complete
      await dispatch(getPartName(rolecode.Admincode ? stationcode : operatorCode));

  
    

    };

    fetchData();
  }, [operatorCode, rolecode?.Admincode, stationcode, partname, dispatch]);

  useEffect(() => {
    setError1("");
    const fetchData = async () => {
      const obj = {
        code: rolecode?.Admincode ? stationcode : operatorCode,
        name: partname,
      };
      const response = await dispatch(getBomstation(obj));
      setMessages(response?.payload?.data?.dto);
      setSteps(
        response?.payload?.data?.dto?.stations?.map((item) => ({
          code: item.stationcode,
          label: item.stationname,
          buttonName: item.recstatus,
        }))
      );

      response?.payload?.response?.data?.aceErrors
        ? setError1(
            response?.payload?.response?.data?.aceErrors[0]?.errorMessage
          )
        : setError1(response?.payload?.response?.data);
    };

    fetchData();
    setExpandedStep(-1);
  }, [partname]);

  useEffect(() => {
    dispatch(getStation());
    
    return ()=> {
      dispatch(setfilterClear());
    }
   
  }, []);

  useEffect(() => {
    if (filteredMessages && filteredMessages.stations && filteredMessages.stations.length > 0) {
      setSteps(
        filteredMessages.stations.map((item) => ({
          code: item.stationcode,
          label: item.stationname,
          buttonName: item.recstatus,
        }))
      );
      setMessages(filteredMessages);
      setExpandedStep(-1);
  
      // Show Print QR Code
      if (
        filteredMessages.header &&
        filteredMessages.header.stationcode?.toUpperCase() === "LAMAST01" &&
        filteredMessages.header.stationcode?.toUpperCase() === localStorage.getItem("operatorCode")?.toUpperCase()
      ) {
        
       
        setLink(`{"PARTNO":${filteredMessages.header?.name}, "VENDORID": "11694-1"}`);
        setTitle(filteredMessages.header?.name);
      }
    } else {
      setMessages([]);
      setSteps([]);
    
    }
  }, [filteredMessages]);
  
  // handle QR code 
  const [openPrint, setOpenPrint] = useState(false);

  const handleClose =()=> setOpenPrint(!openPrint)
  const [QRLink, setQRLink] = useState('');

  const handleShowQR =(item) =>{
    setQRLink(`${UI_BASE_URL}/app/Steps/steps-ui/${item.stationcode}/${item.name}`)
    handleClose()
  }
  

  // const handleStepToggle = (index) => {
  //   console.log("index", index);
  //   const currentStation = messages?.stations[index];
  //   const filterData = messages?.stations?.find(
  //     (i) => i.stationname === currentStation?.stationname
  //   );

  //   setData({
  //     code: filterData?.stationcode,
  //     name:
  //       filteredMessages?.length == 0
  //         ? partname
  //         : filteredMessages?.header?.name,
  //     type: messages?.header?.type,
  //   });
  //   setExpandedStep(expandedStep === index ? -1 : index);
  // };
  const handleStepToggle = (index) => {
    const currentStation = messages?.stations[index];
    const filterData = messages?.stations?.find(
      (i) => i.stationname === currentStation?.stationname
    );

    setData({
      code: filterData?.stationcode,
      name:
        filteredMessages?.length === 0
          ? partname
          : filteredMessages?.header?.name,
      type: messages?.header?.type,
    });
    setExpandedStep((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {rolecode.Admincode ? (
        <MDBox>
          <div style={addButtonStyle}>
            <span
              style={{
                alignSelf: "flex-start",
                marginTop: "20px",
              }}
            >
              <Autocomplete
                defaultValue={defaultMMU}
                disablePortal
                id="combo-box-demo"
                sx={{ width: 280 }}
                {...defaultpropsstation}
                onChange={(event, newValue) => {
                  setStationcode(newValue?.code);
                  localStorage.setItem("operatorCode", newValue?.code);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Station" />
                )}
              />
            </span>
          </div>
        </MDBox>
      ) : (
        <></>
      )}
      {loading ? ( // Conditionally render loader based on the 'loading' state
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {messages?.length == 0 ||
          messages == undefined ||
          messages?.stations?.length == 0 ? (
            <Errorcard error={"Data not found."} />
          ) : (
            <>
              <ProductDetailCard
                data={messages?.header}
                name={messages?.header?.name}
                code={messages?.header?.stationcode}
              />
              {/* {messages?.header} */}
              <Grid container mt={4} mb={3}>
                <Grid item xs={12} sm={11.7}>
                  <Timeline align="left">
                    {steps?.map((step, index) => (
                      <TimelineItem key={step.label}>
                        <TimelineOppositeContent
                          style={{ flex: 0 }}
                        ></TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />
                          {index < steps?.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Accordion
                            expanded={expandedStep === index}
                            onChange={() => handleStepToggle(index)}
                            style={{
                              marginTop: expandedStep === index ? -20 : -20,
                              marginBottom: expandedStep === index ? 10 : 0,
                            }}
                          >
                            <AccordionSummary>
                              <Typography
                                style={{ textTransform: "uppercase" }}
                              >
                                {" "}
                                {`${step.label.toUpperCase()} (${step.code})`}
                              </Typography>
                              &nbsp;&nbsp;&nbsp;
                              {step.buttonName == "OK" ? (
                                <MDButton
                                  variant="contained"
                                  color="success"
                                  size="small"
                                >
                                  {/* {step.buttonName} */}
                                  PASS
                                </MDButton>
                              ) : (
                                <MDButton
                                  variant="contained"
                                  color="error"
                                  size="small"
                                >
                                  Fail
                                </MDButton>
                              )}
                            </AccordionSummary>
                            <AccordionDetails>
                              {expandedStep === index && (
                                <div>
                                  <ItemAccordian data={data} key={step.label} />

                                  <MDButton
                                    variant="outlined"
                                    color="success"
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setExpandedStep(-1);
                                    }}
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                    style={{
                                      marginTop: 7,
                                      marginLeft: "10px",
                                      backgroundColor: hovered
                                        ? "blue"
                                        : "transparent",
                                      color: hovered ? "white" : "green",
                                      border: `1px solid ${
                                        hovered ? "transparent" : "green"
                                      }`,
                                    }}
                                  >
                                    <CloseIcon />
                                    &nbsp; CLOSE
                                  </MDButton>
                                </div>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      
    </DashboardLayout>
  );
};

export default WebSocketUI;
